
import template from './email-message-view.html';
import Message from '../../models/Message.js';

/**
 * @kind component
 * @class ComponentViewModel
 * @description Component for email message edit
 */
class ComponentViewModel
{
	constructor (params, element)
	{
		this.editor = null;

		this.params = params;
		this.element = element;
		this.current_page_id = ko.observable('html');
		this.message_input = ko_helper.safe_observable(params.message);
		this.message = ko.observable();
		this.collapsed = ko_helper.safe_observable(params.collapsed || true);
		this.addresses = ko_helper.safe_observable(params.addresses ? params.addresses : 'true'); // hide or show addresses input

		this.init();
	}

	async init()
	{
		if (!(this.message_input() instanceof Message))
		{
			const msg = new Message();
			await msg.set(this.message_input());
			this.message(msg);
		}
		else
			this.message(this.message_input());

		if (this.message() && (!this.message()?.addressList() || this.message()?.addressList()?.length==0))
			this.message().set({'address-full':[{ role: "from", type: "email", name: Grape.currentSession.user.email || this.message().channel()?.default_from_address || ''}] });

		// 
		const editor_element = this.element.querySelector('.froala-editor');
		await new Promise((resolve) => {
			this.editor = new FroalaEditor(editor_element, {
				key: Grape.froala.key,
				attribution: false, // remove "Powered by" watermark
				tableResizerOffset: 10,
				tableResizingLimit: 50
			}, resolve);
		});

		await this.set_message();

		// TODO remove
		this.message().getPropertyObservable('subject').subscribe((data) => {
			let str = data;
			if(str && data.length > 30)
				str = data.slice(0, 30)+'...'
			this.message().setPropertyValue('preview', str || '');
		});
		
		this.message.subscribe(() => this.set_message());
	}

	/* called when message changes */
	async set_message()
	{
		if (this.message())
		{
			await this.setMessageContent();

			if (this.editor.edit !== undefined)
			{
				this.editor.edit.off();
				this.editor.toolbar.hide();
			}

			let str = this.message().getPropertyValue('subject');
			if(str && str.length > 30)
				str = str.slice(0, 30)+'...'

			this.message().setPropertyValue('preview', str);
		}
	}

	async setMessageContent ()
	{
		const body = this.message().propertyList().find(prop => prop.name === 'body');

		if (body?.name == 'body' && this.message().contentList().length <= 0)
		{
			let newHtmlContent = this.message().addContent('htmlBody', 'body', { type: 'text', subtype: 'html' })

			newHtmlContent.setBody(`<p>${body.value()}</p>`);
			await newHtmlContent.saveBody();
			this.editor.html.set(`<p>${body.value()}</p>`);
		}

		for (let content of this.message().contentList())
		{
			if (content.name() == 'textBody')
			{ 
				if (content.property.filename)
					await content.fetchBody();
				else if (content.property.body)
					await content.setBody(content.property);
			}
			else if (content.name() == 'htmlBody')
			{
				let body = await content.fetchBody();
				this.editor.html.set(body);
			}
		}
	}

	load_tab (page_id)
	{
		this.current_page_id(page_id);
	}
}

export default {
	name: 'email-message-view',
	viewModel: ComponentViewModel,
	module_type: 'ko',
	template: template
};