import PropertyList from './PropertyList.js';

class MessageContent extends PropertyList
{
	constructor (message_uuid, obj)
	{
		super();

		this.message_uuid = ko.observable(message_uuid);
		this.content_nr = ko.observable();
		this.name = ko.observable();
		this.role = ko.observable();
		this.body = ko.observable();
		this.type = ko.observable();
		this.subtype = ko.observable();

		if (obj)
			this.set(obj);
	}

	async set (obj)
	{
		if (obj.hasOwnProperty('content_nr'))
			this.content_nr(obj.content_nr);
		if (obj.hasOwnProperty('name'))
			this.name(obj.name);
		if (obj.hasOwnProperty('property'))
		{
			super.set(obj.property);
			if (super.getPropertyValue('type'))
				this.type (super.getPropertyValue('type'));
			if (super.getPropertyValue('subtype'))
				this.subtype (super.getPropertyValue('subtype'));
		}
		if (obj.hasOwnProperty('role'))
			this.role(obj.role);

		return await this.fetchBody();
	}

	set_message_uuid (message_uuid)
	{
		this.message_uuid(message_uuid);
	}

	/**
	 * Get Response object for content
	 */
	async fetchBody ()
	{
		if (!this.message_uuid())
			return;

		const result = await Grape.fetches.fetch(`/api/messages/message/content?message_uuid=${this.message_uuid()}&name=${this.name()}`);

		let data = await result.text();
		this.body(data || '');

		return data;
	}

	async setBody (data)
	{
		this.body(data.body || data || '');
	}

	async saveBody ()
	{
		if (!this.type() || !this.subtype())
			throw new Error('content type is null');
		if (!this.role())
			throw new Error('role is null');
		if (this.message_uuid() === undefined)
			throw new Error('message_uuid is undefined');
		const formData = new FormData();
		const rs = new Blob([this.body()], {type: `${this.type()}/${this.subtype()}`});
		formData.append(this.role(), rs, this.name());

		let url = `/api/messages/message/content?message_uuid=${this.message_uuid()}`;

		if (this.content_nr() !== undefined)
			url += `&content_nr=${this.content_nr()}`;
		let request = await fetch(`/api/messages/message/content?message_uuid=${this.message_uuid()}`, {
			method: 'POST',
			body: formData
		});

		let data = await request.json();

		if (data.status == 'ERROR')
			throw new Error(data.message || data.error || data.status)
	}
}

export default MessageContent;
