
// COMPONENTS
import fml from './folder-message-list/folder-message-list.js';
import genmsgli from './message-li-view/message-li-view.js';
import genmsgedit from './generic-message-edit/generic-message-edit.js';
import emailmsgedit from './email-message-edit/email-message-edit.js';
import emailmsgview from './email-message-view/email-message-view.js';
import msgaddress from './message-address.js';

export default [
	fml,
	genmsgli,
	genmsgedit,
	emailmsgedit,
	emailmsgview,
	msgaddress
];
