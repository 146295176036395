import template from './generic-message-edit.html';
import Message from '../../models/Message.js';

/**
 * @kind component
 * @class ComponentViewModel
 * @description Component for generic message edit
 */
class ComponentViewModel
{
	constructor (params, element)
	{
		this.editor = null;

		this.params = params;
		this.element = element;
		this.tabs = ko.observableArray([
			{
				title: 'Text',
				page_id: 'text',
				icon: 'fa-solid fa-info-circle'
			},
			{
				title: 'HTML',
				page_id: 'html',
				icon: 'fa-solid fa-file-code'
			},
			{
				title: 'Attachments',
				page_id: 'attachments',
				icon: 'fa-solid fa-upload'
			},
			{
				title: 'Properties',
				page_id: 'advanced',
				icon: 'fa-solid fa-arrow-progress'
			}
		]);
		this.current_page_id = ko.observable('html');

		this.disabled = ko.observable();
		this.message_input = ko_helper.safe_observable(params.message);
		this.message = ko.observable();
		this.addresses = ko_helper.safe_observable(params.addresses ? params.addresses : 'true');

		this.init();
	}

	async init()
	{
		await new Promise((resolve) => {
			this.editor = new FroalaEditor(`#froala-editor`, {
				toolbarButtons: this.getToolbarButtons(),
				tableResizerOffset: 10,
				tableResizingLimit: 50
			}, resolve);
		});

		await this.set_message();

	}

	async init()
	{

		if (!(this.message_input() instanceof Message))
		{
			const msg = new Message();
			await msg.set(this.message_input());
			this.message(msg);
		}
		else
			this.message(this.message_input());

		if (this.message() && (!this.message()?.addressList() || this.message()?.addressList()?.length==0))
			this.message().set({ "address-full": { role:"from", type:"email", name: Grape.currentSession.user.email || this.message().channel()?.default_from_address || '' }});

		const editor_element = this.element.querySelector('.froala-editor');
		await new Promise((resolve) => {
			this.editor = new FroalaEditor(editor_element, {
				key: Grape.froala.key,
				toolbarButtons: Grape.froala.toolbarButtons,
				attribution: false, // remove "Powered by" watermark
				tableResizerOffset: 10,
				tableResizingLimit: 50
			}, resolve);
		});

		await this.set_message();

		// TODO remove
		this.message().getPropertyObservable('subject').subscribe((data) => {
			let str = data;
			if(str && data.length > 30)
				str = data.slice(0, 30)+'...'
			this.message().setPropertyValue('preview', str || '');
		});
		
		this.message.subscribe(() => this.set_message());
	}
	/* called when message changes */
	async set_message()
	{
		if (this.message())
		{
			for (let content of this.message().contentList())
			{
				if (content.name() == 'htmlBody')
				{
					await content.fetchBody();
					this.editor.html.set(content.body());
				}
				else if (content.name() == 'textBody' && content.property.filename)
                    await content.fetchBody();
                else if (content.name() == 'textBody' && content.property.body)
                    await content.setBody(content.property);
			}

			if (this.editor.edit !== undefined && this.message().status() == 'Sent')
				this.editor.edit.off();
		}
	}

	load_tab (page_id)
	{
		this.current_page_id(page_id);
	}

	async onContentFileUpload(event)
	{
		await this.message().uploadContent(event.target.files);
		await this.message().fetch(['content.property']);
	}

	async getHtml ()
	{
		const html = await this.message().getContentTextByName('htmlBody');
		return html;
	}

	getToolbarButtons ()
	{
		return [
			[
				'fullscreen',
				'undo',
				'redo',
				'getPDF',
				'print'
			],
			[
				'bold',
				'italic',
				'underline',
				'textColor',
				'backgroundColor',
				'clearFormatting'
			],
			[
				'alignLeft',
				'alignCenter',
				'alignRight',
				'alignJustify'
			],
			[
				'formatOL',
				'formatUL',
				'indent',
				'outdent'
			],
			[
				'paragraphFormat'
			],
			[
				'fontFamily'
			],
			[
				'fontSize'
			],
			[
				'insertLink',
				'insertImage',
				'quote',
				'table',
				'html'
			]
		]
	}

	deleteContent (content)
	{
		let updatedContentList = this.message().contentList().filter(x => x.name() !== content.name());
		this.message().contentList(updatedContentList);
	}
}

export default {
	name: 'generic-message-edit',
	viewModel: ComponentViewModel,
	module_type: 'ko',
	template: template
};