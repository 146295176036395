
import PropertyList from './PropertyList.js';
import MessageContent from './MessageContent.js';

class Message extends PropertyList
{
	constructor(obj)
	{
		super ();

		this.message_uuid = ko.observable();
		this.conversation_uuid = ko.observable();
		this.order_nr = ko.observable();
		this.address = ko.observable();
		this.from = ko.observable();
		this.status = ko.observable();
		this.channel_name = ko.observable();
		this.channel = ko.observable();
		this.addressList = ko.observableArray();
		this.removedAddressList = ko.observableArray();
		this.contentList = ko.observableArray();

		if (obj)
			this.set(obj);
	}

	async set(obj)
	{
		if (obj.hasOwnProperty('message_uuid'))
			this.message_uuid(obj.message_uuid);
		if (obj.hasOwnProperty('conversation_uuid'))
			this.conversation_uuid(obj.conversation_uuid);
		if (obj.hasOwnProperty('status'))
			this.status(obj.status != 'ERROR' ? obj.status : 'New');
		if (obj.hasOwnProperty('order_nr'))
			this.order_nr(obj.order_nr);
		if (obj.hasOwnProperty('channel'))
		{
			if (typeof obj.channel == 'string')
			{
				this.channel_name(obj.channel);
				const Channels = await Grape.cache.get('Channels');
				this.channel(Channels.find((x) => x.name === obj.channel));
			}
			else if (obj.channel instanceof Object)
			{
				this.channel(obj.channel);
				this.channel_name(obj.channel.name);
			}
			else if (obj.channel === null);
			else
				throw new Error('Invalid type for channel');
		}
		if (obj.hasOwnProperty('property'))
			super.set(obj.property);
		
		if (obj.hasOwnProperty('content'))
		{
			const list = [];
			if (Array.isArray(obj.content))
				for (let i of obj.content)
				{
					let content = new MessageContent(obj.message_uuid)
					await content.set(i);
					list.push(content);
				}

			this.contentList(list);
		}

		if (obj.hasOwnProperty('address'))
		{
			const list = [];
			for (let [role, v] of Object.entries(obj['address']))
				if (Array.isArray(v))
					for (let a of v)
						list.push({ role: ko.observable(role), name: ko.observable(a) });
				else if (v)
					list.push({ role: ko.observable(role), name: ko.observable(v) });

			this.addressList(list);
		}
		if (obj.hasOwnProperty('address-full'))
		{
			const list = [];
			if (Array.isArray(obj['address-full']))
				for (let a of obj['address-full'])
					list.push({
						role: ko.observable(a.role),
						name: ko.observable(a.name),
						type: a.type,
						address_uuid: a?.address_uuid
					});

			this.addressList(list);
		}
	}

	addAddress ()
	{
		this.addressList.push({
			address_uuid: null,
			name: ko.observable(''),
			role: ko.observable('to'),
			type: null
		});
	}

	removeAddress (address)
	{
		this.addressList.pop(address);

		this.removedAddressList.push(address);
	}

	addContent (name, role, property = {})
	{
		const content = new MessageContent(this.message_uuid(), Object.assign({ name, role, property }))
		this.contentList.push(content);
		return content;
	}

	async deleteContent (content_nr)
	{
		try
		{
			let result = await fetch(`/api/messages/message/content?message_uuid=${this.message_uuid()}&content_nr=${content_nr}`, {
				method: 'DELETE',
				headers: { 'content-type': 'application/json' },
			});
		
			let data = await result.json();
		
			if (data.status == 'OK')
			{
				let updatedContentList = this.contentList().filter(x => x.content_nr() !== content_nr);
				this.contentList(updatedContentList);
				Grape.alerts.alert({ type: 'success', title: 'Success', message: 'Content was successfully deleted.' });
			}
			else
				throw new Error(data.error || data.code || result.status);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	getUniqueParticipants()
	{
		const address = [];
		for (let item of this.addressList() || [])
			if (address.indexOf(item.name()) == -1)
				address.push(item.name());

		return address;
	}

	async fetch(fields)
	{
		const result = await Grape.fetches.getJSON(`/api/messages/message/${this.message_uuid()}`, {fields});
		this.set(result);
	}

	async save()
	{
		if (this.message_uuid() == null)
			return this.create();
		else
			return this.update();
	}

	async create(options={})
	{
		const obj = Object.assign({}, this.toJS(), options);
		const result = await Grape.fetches.postJSON('/api/messages/message', obj);

		this.message_uuid(result.message_uuid);
		this.conversation_uuid(result.conversation_uuid);

		this.saveContentBodies();

		return this.message_uuid();
	}

	/**
	 * Save all content with bodies
	 */
	async saveContentBodies()
	{
		for (let content of this.contentList())
		{
			if (content.message_uuid() === undefined)
				content.set_message_uuid(this.message_uuid());

			if (content.body())
				await content.saveBody();
		}
	}

	async update()
	{
		const obj = this.toJS();
		await Grape.fetches.postJSON(`/api/messages/message/${this.message_uuid()}`, obj);
		this.saveContentBodies();
	}

	async uploadContent(files)
	{
		let message_uuid = this.message_uuid();
		if (!message_uuid)
			message_uuid = await this.create();

		const formData = new FormData();
		for (let file of files)
			if (file.hasOwnProperty('role')) /* Must be one of attachment, inline, body or internal */
				formData.append(file.role, file);
			else
				formData.append('attachment', file);

		const result = await Grape.fetches.fetchJSON(`/api/messages/message/content?message_uuid=${message_uuid}`, {
			method: 'POST',
			body: formData
		});

		return result;
	}

	/**
	 * Get Response object for content
	 */
	async getContentByNr(content_nr)
	{
		const response = await Grape.fetches.fetch(
			`/api/messages/message/content?message_uuid=${this.message_uuid()}&content_nr=${content_nr}`
		);
		return response;
	}

	/**request.ok
	 * Get text data for content
	 */
	async getContentTextByNr (content_nr)
	{
		const result = await this.getContentByNr(content_nr);
		const data = await result.text();
		return data;
	}

	/**
	 * Get blob data for content
	 */
	async getContentBlobByNr (content_nr)
	{
		const result = await this.getContentByNr(content_nr);
		const data = await result.blob();
		return data;
	}

	/**
	 * Get Response object for content
	 */
	async getContentByName (name)
	{
		const result = await Grape.fetches.fetch(
			`/api/messages/message/content?message_uuid=${this.message_uuid()}&name=${name}`
		);
		return result;
	}

	/**
	 * Get text data for content
	 */
	async getContentTextByName (name)
	{
		const result = await this.getContentByName(name);
		const data = await result.text();
		return data;
	}

	/**
	 * Get blob data for content
	 */
	async getContentBlobByName (name)
	{
		const result = await this.getContentByName(name);
		const data = await result.blob();
		return data;
	}

	/**
	 * Send message
	 */
	async send ()
	{
		const result = await Grape.fetches.postJSON(`/api/messages/${this.message_uuid()}/send`, {});
		return result;
	}

	/**
	 * Serialize
	 */
	toJS()
	{
		const property = {};
		for (let item of this.propertyList() || [])
			property[item.name] = item.value();
		
		const address = [];
		for (let item of this.addressList() || [])
		{
			if (item.name() != '')
				address.push({
					address_uuid: item.uuid,
					name: item.name(),
					role: item.role(),
					type: item.type,
					fields: item.fields
				});
		}

		const obj = {
			channel: this.channel()?.name || null,
			driver: this.channel()?.driver || null,
			message_uuid: this.message_uuid(),
			conversation_uuid: this.conversation_uuid(),
			status: this.status(),
			order_nr: this.order_nr(),
			address: address,
			property: property
		};

		if (this.removedAddressList().length > 0)
			obj.remove_address = this.removedAddressList();

		return obj;
	}
}

export default Message;
