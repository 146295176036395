import template from './email-message-edit.html';
import Message from '../../models/Message.js';

/**
 * @kind component
 * @class ComponentViewModel
 * @description Component for email message edit
 */
class ComponentViewModel
{
	constructor (params, element)
	{
		this.editor = null;

		this.params = params;
		this.element = element;
		this.tabs = ko.observableArray([
			{
				title: 'HTML',
				page_id: 'html',
				icon: 'fa-solid fa-file-code'
			},
			{
				title: 'Attachments',
				page_id: 'attachments',
				icon: 'fa-solid fa-upload'
			},
			{
				title: 'Properties',
				page_id: 'advanced',
				icon: 'fa-solid fa-arrow-progress'
			}
		]);
		this.current_page_id = ko.observable('html');
		this.message_input = ko_helper.safe_observable(params.message);
		this.message = ko.observable();
		this.show_save_btn = ko_helper.safe_observable(params.show_save_btn || false);
		this.show_send_btn = ko_helper.safe_observable(params.show_send_btn || false);
		this.templates = ko.observableArray([]);

		this.addresses = ko_helper.safe_observable(params.addresses ? params.addresses : 'true'); // hide or show addresses input

		this.init();
	}

	async init()
	{
		if (!(this.message_input() instanceof Message))
		{
			const msg = new Message();
			await msg.set(this.message_input());
			this.message(msg);
		}
		else
			this.message(this.message_input());

		if (this.message() && (!this.message()?.addressList() || this.message()?.addressList()?.length==0))
			this.message().set({'address-full':[{ role: "from", type: "email", name: Grape.currentSession.user.email || this.message().channel()?.default_from_address || ''}] });

		// 
		const editor_element = this.element.querySelector('.froala-editor');
		await new Promise((resolve) => {
			this.editor = new FroalaEditor(editor_element, {
				key: Grape.froala.key,
				toolbarButtons: this.getToolbarButtons(),
				attribution: false, // remove "Powered by" watermark
				tableResizerOffset: 10,
				tableResizingLimit: 50
			}, resolve);
		});

		await this.set_message();
		await this.getTemplates();

		// TODO remove
		this.message().getPropertyObservable('subject').subscribe((data) => {
			let str = data;
			if(str && data.length > 30)
				str = data.slice(0, 30)+'...'
			this.message().setPropertyValue('preview', str || '');
		});
		
		this.message.subscribe(() => this.set_message());
	}

	/* called when message changes */
	async set_message()
	{
		if (this.message())
		{
			let exist = false;
			for (let a of this.message().addressList())
				if(a.role() === 'to')
					exist = true;

			if(!exist)
				this.message().addressList().push({
					address_uuid: null,
					type: 'email',
					name: ko.observable(''),
					role: ko.observable('to')
				});

			const bodyProperty = this.message().propertyList().find(prop => prop.name === 'body');
			let htmlContent = null;

			for (let content of this.message().contentList())
			{
				if (content.name() == 'textBody')
				{ 
					if (content.property.filename)
						await content.fetchBody();
					else if (content.property.body)
						await content.setBody(content.property);

					if (this.tabs().some(tab => tab.page_id === 'text') == false)
					{
						this.tabs.unshift({
							title: 'Text',
							page_id: 'text',
							icon: 'fa-solid fa-info-circle'
						});
						this.current_page_id('text');
					}
				}
				else if (content.name() == 'htmlBody')
				{
					await content.fetchBody();

					htmlContent = content;
				}
			}

			if (bodyProperty?.value() && !htmlContent)
			{
				htmlContent = this.message().addContent('htmlBody', 'body', { type: 'text', subtype: 'html' });
				htmlContent.setBody(bodyProperty.value());
			}
			else if (!htmlContent)
			{
				htmlContent = this.message().addContent('htmlBody', 'body', { type: 'text', subtype: 'html' });
				htmlContent.setBody('<p>reply to</p>');
			}

			if (this.editor.edit !== undefined && this.message().status() == 'Sent')
				this.editor.edit.off();

			if (htmlContent)
				if (htmlContent.body())
					this.editor.html.set(htmlContent.body());

			let str = this.message().getPropertyValue('subject');
			if(str && str.length > 30)
				str = str.slice(0, 30)+'...'

			this.message().setPropertyValue('preview', str);
		}
	}

	async getTemplates ()
	{
		try 
		{
			const result = await Grape.fetches.getJSON(`/api/record`, { 
				schema: 'messages', 
				table: 'v_templates',
				filter: [
					{
						field: 'driver_name',
						operand: '=',
						value: 'Email'
					}
				]
			});

			if (result.status == 'OK')
				this.templates(result.records || []);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	getToolbarButtons ()
	{
		return [
			[
				'html',
				'fullscreen',
				'undo',
				'redo'
			],
			[
				'bold',
				'italic',
				'underline',
				'textColor',
				'backgroundColor',
				'inlineStyle',
				'clearFormatting'
			],
			[
				'fontFamily',
				'fontSize'
			],
			[
				'alignLeft',
				'alignCenter',
				'alignRight',
				'alignJustify'
			],
			[
				'formatOL',
				'formatUL',
				'indent',
				'outdent'
			],
			[
				'paragraphFormat',
				'paragraphStyle'
			],
			[
				'insertLink',
				'insertImage',
				'insertTable',
				'insertFile'
			],
			[
				'trackChanges',
				'markdown'
			]
		]
	}

	load_tab (page_id)
	{
		this.current_page_id(page_id);
	}

	async onContentFileUpload(event)
	{
		await this.message().uploadContent(event.target.files);
		await this.message().fetch(['content.property']);
	}

	async getHtml ()
	{
		const html = await this.message().getContentTextByName('htmlBody');
		return html;
	}

	async btnSave ()
	{
		for(let content of this.message().contentList())
			if (content.name() === 'htmlBody')
				content.setBody(this.editor.html.get());

		await this.message().save();
		await this.message().saveContentBodies();
		this.params.saveCallback();
	}

	async btnSend ()
	{
		if (!(await Grape.alerts.confirm({ type: 'warning', title: 'Confirm',  message: 'Are you sure you want to send this message?' })))
			return;

		for(let content of this.message().contentList())
			if (content.name() === 'htmlBody')
				content.setBody(this.editor.html.get());

		await this.message().setPropertyValue('envelopedate', new Date().toISOString());
		await this.message().save();
		await this.message().send();
		this.params.sendCallback();
	}

	deleteContent (content)
	{
		let updatedContentList = this.message().contentList().filter(x => x.name() !== content.name());
		this.message().contentList(updatedContentList);
	}
}

export default {
	name: 'email-message-edit',
	viewModel: ComponentViewModel,
	module_type: 'ko',
	template: template
};