
import template from './edit-message.html';
import Message from '../../models/Message.js';

/**
 * @kind dialog view model
 * @class EditMessageDialogViewModel
 * @description Dialog for editing/viewing messages
 */
class EditMessageDialogViewModel
{
	/**
	 * @type ViewModel constructor.
	 * @param {EditMessageDialogClass} dialog - EditMessageDialog class
	 */
	constructor(dialog)
	{
		this.dialog = dialog;

		this.status = ko.observable();
		this.channels = ko.observableArray();
		this.selectedDriver = ko.observable();
		this.folder = ko.observable();
		this.message = ko.observable();
		this.send_result = ko.observable();
	}

	/**
	 * Handle click event for save button
	 */
	async btnSave_click()
	{
		if(typeof this.message().message_uuid() === 'undefined')
			await this.message().create({ folder_name: this.folder().name() });
		
		for (let content of this.message().contentList())
			if (content.name() === 'htmlBody')
				content.body(new FroalaEditor('#froala-editor').html.get());

		await this.message().save();
		await this.message().fetch();
	}

	/**
	 * Handle click event for close button
	 */
	btnClose_click()
	{
		this.dialog.close(false);
	}

	/**
	 * Send button click event
	 */
	async btnSend_click()
	{
		if (!(await Grape.alerts.confirm('Are you sure you want to send?')))
			return;

		this.status('sending');

		if(typeof this.message().message_uuid() === 'undefined')
			await this.message().create({ folder_name: this.folder().name() });

		const result = await this.message().send();

		this.status('sent');
	}
}

/**
 * @kind dialog class
 * @class EditMessageDialogClass
 */
class EditMessageDialogClass
{
	/**
	 * @kind constructor
	 * @param {Object} bindings -
	 */
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;

		this.viewModel = new EditMessageDialogViewModel(this); // Name of the ViewModel defined above
	}

	get name() { return 'EditMessage'; }

	async init()
	{
		const Channels = await Grape.cache.get('Channels');
		this.viewModel.channels(Channels);

		if (this.bindings.folder)
			this.viewModel.folder(this.bindings.folder);

		if (this.bindings.message)
		{
			const message = this.bindings.message;
			if(typeof message.message_uuid() != 'undefined')
				await message.fetch(['content.property', 'address-full']);

			this.viewModel.message(message);
			let channel = ko.utils.unwrapObservable(this.viewModel.message().channel)
			this.viewModel.selectedDriver(channel.driver);
		}

		this.viewModel.status('ready');
	}
}

export default {
	name: 'EditMessage',
	provider: 'ps',
	dialog_class: EditMessageDialogClass,
	template: template
};
