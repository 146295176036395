import template from './edit-template.html';
import Message from '../../../models/Message.js';
import Template from '../../../models/Template.js';

class EditTemplateVM
{
	constructor (page)
	{
		this.page = page;
		this.title = ko.observable(this.page.bindings.create === true ? 'Create Template' : 'Edit Template');
		this.template = ko.observable();
		this.message = ko.observable();
		this.addresses = ko.observable(false);
		this.drivers = ko.observableArray([]);
		this.selectedDriver = ko.observable();
		this.message_categories = ko.observableArray([]);
		this.templateUUID = ko.observable();
		this.selectedCategories = ko.observableArray();

		this.txtAddress = ko.observable(); // Preview address
		this.txtData = ko.observable('{}'); // Preview input data (must be valid JSON)
		this.channels = ko.observableArray([]);
		this.driverChannels = ko.computed(() => { return this.channels().filter((item) => { return item.driver==this.selectedDriver() }) });
		this.selectedChannel = ko.observable(); // Preview address
	}

	async load()
	{
		this.drivers(await Grape.cache.get('ChannelTypes'));
		this.channels(await Grape.cache.get('Channels'));
		this.message_categories(await Grape.cache.get('MessageCategory'));

		let message;

		if (!this.templateUUID())
		{
			message = new Message();
			await message.set({
				status: 'New'
			});
			await message.create();
			this.message(message);
			this.template(new Template({message_uuid: this.message().message_uuid()}));
		}
		else
		{
			this.template(new Template({template_uuid: this.templateUUID()}));
			await this.template().fetch();
			message = new Message({message_uuid: this.template().message_uuid()});
			await message.fetch(['property', 'content.property']);
			this.message(message);
		}

		for (let el of this.element.getElementsByClassName('tom-select'))
		{
			if (el.tagName.toUpperCase() != 'SELECT')
				continue;
			let tom = new window.TomSelect(el, {});
		}
	}

	async saveCallback()
	{
		await this.template().save();
		Grape.alerts.alert({ type: 'success', title: 'Saved', message: 'Saved'});
	}

	async btnSend()
	{
		await this.message().save();
		await Grape.fetches.postJSON('/api/messages/template-sendout',
		{
			template_uuid: this.templateUUID(),
			to_address: this.txtAddress(),
			channel: this.selectedChannel(),
			template_data: JSON.parse(this.txtData()),
			send_date: (new Date()).toISOString() // Now
		});
	}

	async btnChannel(channel=null)
	{
	}
}

class EditTemplatePage
{
	constructor (bindings, page, pageOptions, element)
	{
		document.title = 'Messages: Edit Template';
		this.bindings = bindings;

		this.viewModel = new EditTemplateVM(this);
		this.viewModel.element = element;
		if (bindings.template_uuid)
			this.viewModel.templateUUID(bindings.template_uuid);
	}

	async init ()
	{
		this.viewModel.load();
	}

	teardown ()
	{
	}
}

export default {
	route: '[/]ui/messages/setup/edit-template',
	page_class: EditTemplatePage,
	template: template
}
