
import Folders from './folders/folders.js';
import Setup from './setup/setup.js';
import SetupChannels from './setup/channels/channels.js';
import SetupTemplates from './setup/templates/templates.js';
import EditTemplate from './setup/templates/edit-template.js';

export default [
	Folders,
	Setup,
	SetupChannels,
	SetupTemplates,
	EditTemplate
];
